import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PropertyinfoService {
  public readonly apiUrl = environment.API_BASE_URL;
  constructor(public http: HttpClient) {}

  addPropertyInfo(data: Object): Observable<any> {
    return this.http.post(this.apiUrl + 'propertyinfo', data).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  getPropertyInfoList(): Observable<any> {
    return this.http.get(this.apiUrl + 'propertyinfo/all').pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  getPropertyClaimList(): Observable<any> {
    return this.http.get(this.apiUrl + 'propertyinfo/claim/all').pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  transferClaim(property_id: string, txnDetail: object): Observable<any> {
    return this.http
      .post(this.apiUrl + 'propertyinfo/claim', { property_id, txnDetail })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  rejectClaim(property_id: string, ethAddress: string): Observable<any> {
    return this.http
      .post(this.apiUrl + 'propertyinfo/claim-reject', {
        property_id,
        ethAddress,
      })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  transferList(): Observable<any> {
    return this.http.get(this.apiUrl + 'transfer/all').pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  updateTransfer(id:string, data: any): Observable<any> {
    return this.http.patch(this.apiUrl + 'transfer/' + id, data).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }
}
