// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_BASE_URL: 'https://api.lunafund.io/v1/',
  LRX_CONTRACT_ADDRESS: '0x9c75E112502229f4bD81c216769d3558976f47c8',
  ETH_PUBLIC_KEY: '0xDd229248baf1Da57558ec4ab2A5E64588F62D72c',
  PROJECT_ID: '2FoQ0DqGOT67dBf1oJJJsC3AzSL',
  SECRET: '8f3b6458440eec9156a8ce6af757e5d7'
  // LRX_CONTRACT_ADDRESS: '0x0045A1212Df23a352C93eadF74d9B8586bA4D844',
  // ETH_PUBLIC_KEY: '0x0cE1E760252Fd26c924c24F7f25b269B2b979c2A',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
