import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
const Web3 = require('web3');
const ethers = require('ethers');
declare let require: any;
declare let window: any;
const tokenAbi = require('../contract/abis.json');

@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  private account: any = null;
  private readonly web3: any;
  private enable: any;
  private contract: any;

  constructor() {
    if (window.ethereum === undefined) {
      alert('Non-Ethereum browser detected. Install MetaMask');
    } else {
      if (typeof window.web3 !== 'undefined') {
        this.web3 = window.web3.currentProvider;
      } else {
        this.web3 = new Web3.providers.HttpProvider('http://localhost:8545');
      }

      console.log('transfer.service :: constructor :: window.ethereum');
      window.web3 = new Web3(window.ethereum);
      console.log('transfer.service :: constructor :: this.web3');
      console.log(this.web3);
      this.enable = this.enableMetaMaskAccount();
      console.log(this.enable);
    }
  }

  private async enableMetaMaskAccount(): Promise<any> {
    let enable = false;
    await new Promise(async (resolve, reject) => {
      if (window.ethereum) {
        enable = await window.ethereum.enable();

        if (enable) {
          this.getUserBalance();
          this.connectContract();
        }
      }
    });
    return Promise.resolve(enable);
  }

  public async convertJSONtoHEX(value) {
    return window.web3.utils.toHex(value);
  }

  public async connectContract() {
    this.contract = await new window.web3.eth.Contract(
      tokenAbi,
      environment.LRX_CONTRACT_ADDRESS
    );
  }

  public async createPropertyNFTs(id, acres, byteData) {
    // var contract = await this.connectContract();
    console.log(byteData);
    
    var receipt = await this.contract.methods
      .createToken([id], [acres], byteData)
      .send({ from: this.account })
      .once('receipt', (receipt) => {
        console.log('receipt==========', receipt);
      })
      .catch((error) => {
        console.log('error==========', error);
      });

    return receipt;
  }

  private async getAccount(): Promise<any> {
    console.log('transfer.service :: getAccount :: start');
    if (this.account == null) {
      this.account = (await new Promise((resolve, reject) => {
        console.log('transfer.service :: getAccount :: eth');
        console.log(window.web3.eth);
        window.web3.eth.getAccounts((err, retAccount) => {
          console.log('transfer.service :: getAccount: retAccount');
          console.log(retAccount);
          console.log(err);
          if (retAccount.length > 0) {
            this.account = retAccount[0];
            resolve(this.account);
          } else {
            alert('transfer.service :: getAccount :: no accounts found.');
            reject('No accounts found.');
          }
          if (err != null) {
            alert('transfer.service :: getAccount :: error retrieving account');
            reject('Error retrieving account');
          }
        });
      })) as Promise<any>;
    }
    return Promise.resolve(this.account);
  }

  public async getUserBalance(): Promise<any> {
    const account = await this.getAccount();
    console.log('transfer.service :: getUserBalance :: account');
    console.log(account);
    return new Promise((resolve, reject) => {
      window.web3.eth.getBalance(account, function (err, balance) {
        console.log('transfer.service :: getUserBalance :: getBalance');
        console.log(balance);
        if (!err) {
          const retVal = {
            account: account,
            balance: balance,
          };
          console.log(
            'transfer.service :: getUserBalance :: getBalance :: retVal'
          );
          console.log(retVal);
          resolve(retVal);
        } else {
          reject({ account: 'error', balance: 0 });
        }
      });
    }) as Promise<any>;
  }

  public async getWalletBlalnce() {
    const balanceNFT = await this.contract.methods
      .balanceOf(environment.ETH_PUBLIC_KEY, 1)
      .call();

    console.log('balanceNFT info ', balanceNFT);
  }

  public async transferOwnership(address) {
    var response = await this.contract.methods
      .transferOwnership(address)
      .send({ from: this.account })
      .once('receipt', (receipt) => {
        console.log('receipt==========', receipt);
      })
      .catch((error) => {
        console.log('error==========', error);
      });

    return response;
  }

  public async transferNft(address, propertyId, hexData, area) {
    await this.connectContract();

    const isOwner = await this.checkOwner(propertyId);

    if (isOwner) {
      var response = await this.contract.methods
        .safeTransferFrom(this.account, address, propertyId, area, hexData)
        .send({ from: this.account })
        .once('receipt', (receipt) => {
          console.log('receipt==========', receipt);
        })
        .catch((error) => {
          console.log('error==========', error);
        });

      return response;
    } else {
      return false;
    }
  }

  public async transferFromNFTs(to, ids, amounts, data, current_owner_address) {
    // var contract = await this.connectContract();
    console.log('ids',ids);

    //amounts = await ethers.BigNumber.from(amounts.toString());
    console.log('amounts', amounts);
    console.log('current_owner_address', current_owner_address);

    var receipt = await this.contract.methods
      .safeTransferFrom(current_owner_address, to, ids, amounts, data)
      .send({ from: this.account })
      .once('receipt', (receipt) => {
        console.log('receipt==========', receipt);
      })
      .catch((error) => {
        console.log('error==========', error);
      });

    return receipt;
  }

  public async checkOwner(propertyId) {
    const isOwner = await this.contract.methods
      ._ownerOf(this.account, propertyId)
      .call();

    console.log(isOwner);
    return isOwner;
  }
}
